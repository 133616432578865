<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        :clearable="clearable"
        dense
        :rules="range ? [] : combinedRules"
        validate-on-blur
        v-bind="$attrs"
        v-on="on"
        @change="v => date = $moment(v).format('YYYY-MM-DD')"
        @click:clear="v => $emit('update:value', range ? [] : '')"
      >
        <template
          v-if="icon"
          #prepend-inner
        >
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            fal fa-calendar-alt
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      v-bind="$attrs"
      landscape
      no-title
      :range="range"
      show-adjacent-months
      :max="maxDate === false ? null : maxDate === true ? $moment().format('YYYY-MM-DD') : maxDate"
      @change="menu = false"
    />
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: [Array,String],
      default: () => [],
    },
    label: {
      type: String,
      default: 'Date',
    },
    icon: {
      type: Boolean,
      default: true,
    },
    range: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    useTimezone: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: [String, Boolean],
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      menu: false,
      valid: true,
      date: this.value,
    }
  },
  computed: {
    dateFormatted: {
      set() {},
      get() {
        if (this.range && this.date.length > 1) {
          return this.$moment(this.date[0]).format('L') + ' → ' + this.$moment(this.date[1]).format('L')
        } else if (this.range && this.date.length) {
          return this.$moment(this.date[0]).format('L')
        } else if (this.date.length) {
          return this.$moment(this.date).format('L')
        } else {
          return null
        }
      },
    },
    combinedRules () {
      return this.rules.concat([
        v => {
          if (v && !this.range) {
            return this.$moment(v).isValid() || 'Not a valid date'
          }
          return true
        },
      ],
      this.rules)
    },
  },
  watch: {
    value: function (value) {
      this.date = value
    },
    date (newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.date && this.date.length) {
          let newDate = this.$moment(this.date);
          if (newDate.isValid() && this.useTimezone) {
            this.date = newDate.format()
          }
        }
        this.$emit('update:value', this.date)
      }
    },
  },
}
</script>
